import { Box } from "@xstyled/styled-components";
import React from "react";

import Paragraph from "../components//typography/Paragraph/paragraph";
import ArrowLink from "../components/common/ArrowLink/arrow-link";
import ContainerNarrow from "../components/layout/ContainerNarrow/container-narrow";
import Layout from "../components/layout/Main/main";
import SEO from "../components/seo";
import Headline from "../components/typography/Headline/headline";

const NotFoundPage = () => (
    <Layout>
        <SEO title="404: Not found" />
        <ContainerNarrow>
            <Box my={5}>
                <Headline textAlign="center">Page not found</Headline>
            </Box>
            <Box my={5}>
                <Headline as="p" variant="h2" textAlign="center" mb={3}>
                    You just hit a page that doesn&#39;t exist... the sadness.
                </Headline>
                <Paragraph textAlign="center" mb={4}>
                    Try to reach another page by using main menu.
                </Paragraph>
                <ArrowLink to="/">Go to homepage</ArrowLink>
            </Box>
        </ContainerNarrow>
    </Layout>
);

export default NotFoundPage;
