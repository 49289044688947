import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Box } from "@xstyled/styled-components";
import PropTypes from "prop-types";
import React from "react";

import S from "./arrow-link.style";

const ArrowLink = ({ to, children }) => (
    <Box textAlign="center">
        <S.link to={to}>
            <S.icon icon={faAngleRight} />
            <S.text>{children}</S.text>
        </S.link>
    </Box>
);

ArrowLink.propTypes = {
    to: PropTypes.string,
    children: PropTypes.node.isRequired,
};

export default ArrowLink;
