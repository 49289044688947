import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "@xstyled/styled-components";
import { Link } from "gatsby";

const S = {};

S.icon = styled(FontAwesomeIcon)`
    margin-right: 2;
    font-size: 4;
    transition: transform 0.25s ease-in-out;
`;

S.text = styled.span`
    font-size: 4;
`;

S.link = styled(Link)`
    color: primary;
    font-weight: bold;
    text-decoration: none;
    transition: color 0.35s ease-in-out;

    &:hover {
        color: dark;

        ${S.icon} {
            transform: translateX(3px);
        }
    }
`;

export default S;
